import { detect as DetectBrowser } from 'detect-browser';
import * as Sentry from '@sentry/browser';
import { API } from '@/API';

/**
 * Functions to check different parts of the user's system are set up to work
 * with Snapshot.
 */

/**
  * Check we get a 200 response code when we send a request to the environment's API endpoint.
  */
export const CheckAPICommunication = () => API.get('')
  .then((response) => {
    if (response.status === 200) {
      return { value: true };
    }
    return { value: false, errorMessage: `API responded with a ${response.status} code.` };
  }).catch((err) => ({ value: false, errorMessage: err }));

/**
 * Check the user is using a compatible browser.
 */
export const CheckBrowserCompatibility = () => {
  const supportedBrowsers = [
    { name: 'chrome', supportedVersionFrom: '63' },
    { name: 'firefox', supportedVersionFrom: '61' },
    { name: 'edge', supportedVersionFrom: '18' },
    { name: 'safari', supportedVersionFrom: '11.1' },
  ];

  // Detect the user's browser using an external library
  const detectedBrowser = DetectBrowser();

  // If we can't detect the user's browser, just return with an error.
  if (!detectedBrowser) {
    return {
      value: false,
      furtherInformation: 'For the best user experience, we recommend using Google Chrome.',
      errorMessage: 'Sorry, we\'re currently unable to identify your browser.',
    };
  }

  // Use @userSupportedBrowser to see if the user's browser matches a supported one
  // that we have defined. If it does, let's check to see if the version is also supported.
  const userSupportedBrowser = supportedBrowsers
    .find((browser) => detectedBrowser.name === browser.name);

  if (!userSupportedBrowser) {
    return {
      value: false,
      furtherInformation: 'For the best user experience, we recommend using Google Chrome.',
      errorMessage: 'Unfortunately, it looks like Snapshot doesn\'t support the browser you\'re currently using.',
    };
  }

  // We now know that the user's browser is supported, it's time to check if the version number is
  // also supported.
  const supportedVersion = Number(userSupportedBrowser.supportedVersionFrom);

  let userVersion = 0;

  if (detectedBrowser.version !== null) {
    userVersion = Number(detectedBrowser.version.split('.')[0]);
  }

  if (userVersion >= supportedVersion) {
    return { value: true };
  }

  // If the user's browser version is not supported, display an error message which tells them the
  // minimum version for their browser they should be using.
  return {
    value: false,
    errorMessage: `Your browser type is supported, but you should upgrade it to at least version ${supportedVersion}.`,
  };
};

/**
 * Check that we can get and set items from local storage.
 */
export const CheckLocalStorage = () => {
  let storage;

  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return { value: true };
  } catch (err) {
    return {
      value: false,
      errorMessage: 'Please enable local storage.',
    };
  }
};

/**
 * Check that Google Analytics is installed.
 */
export const CheckGoogleAnalytics = () => {
  if ((<any>window).ga && (<any>window).ga.loaded) {
    return { value: true };
  }

  return {
    value: false,
    errorMessage: 'Google Analytics doesn\'t seem to be loading correctly at the moment.',
  };
};

/**
 * Check that we can access indexedDB
 *
 * See https://developers.google.com/web/ilt/pwa/working-with-indexeddb
 */
export const CheckIndexedDB = () => {
  if ('indexedDB' in window) return { value: true };

  return {
    value: false,
    errorMessage: 'We can\'t seem to connect to IndexedDB at the moment.',
  };
};

/**
 * Check that Sentry is installed and working.
 */
export const CheckSentry = () => {
  if (!Sentry) {
    return {
      value: false,
      errorMessage: 'Sentry isn\'t loading correctly right now.',
    };
  }

  try {
    Sentry.captureMessage('This is a test message from the Snapshot System Compatibility page.');
    return { value: true };
  } catch (err) {
    return { value: false, errorMessage: err };
  }
};

/**
 * Check that our service worker is instaled and ready
 */
export const CheckServiceWorker = () => {
  // First off, let's check that this browser supports this
  if (!navigator.serviceWorker) {
    return {
      value: false,
      errorMessage: 'This browser does not support Service Workers.',
    };
  }

  // Checks that the Service Worker is installed.
  return navigator.serviceWorker.getRegistration().then((worker) => {
    if (!worker) {
      return {
        value: false,
        errorMessage: 'Service Worker is not currently installed.',
      };
    }

    return { value: true };
  });
};

/**
 * Convert bytes to readable units.
 *
 * Constructed from
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
const bytesToUnit = (bytes: number | undefined): string => {
  if (!bytes) return '';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const iterator = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / (1024 ** iterator)).toFixed(2))} ${sizes[iterator]}`;
};

/**
 * Check Storage Quota
 *
 * Using StorageManager for better cross-browser support.
 * https://stackoverflow.com/questions/35242869/what-is-the-storage-limit-for-a-service-worker
 */
export const CheckStorageQuota = () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    return navigator.storage.estimate().then(({ usage, quota }) => ({
      value: true,
      furtherInformation: `Using ${bytesToUnit(usage)} out of ${bytesToUnit(quota)}.`,
    })).catch((error) => ({
      value: false,
      errorMesage: error.stack,
    }));
  }

  return {
    value: false,
    furtherInformation: 'This browser doesn\'t support the navigator.storage.estimate API',
  };
};
