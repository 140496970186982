































































import Vue from 'vue';
import SLayoutDefault from '@stratumfive/ui-baseplate/src/components/SLayoutDefault/SLayoutDefault.vue';
import SystemCompatibilityTable from '@/components/compatibility/SystemCompatibilityTable.vue';
import CanvasCard from '@/components/shared/CanvasCard.vue';
import SystemStats from '@/components/compatibility/SystemStats.vue';
import QuickActions from '@/components/compatibility/QuickActions.vue';

export default Vue.extend({
  name: 'SystemCompatibility',
  metaInfo: {
    title: 'System Compatibility',
    titleTemplate: '%s - Stratum Snapshot',
  },
  components: {
    SLayoutDefault,
    CanvasCard,
    SystemCompatibilityTable,
    SystemStats,
    QuickActions,
  },
  computed: {
    lastCheckTime(): string | undefined {
      return this.$store.state.systemCompatibility.lastCheckTime;
    },
  },
});
