




































import Vue from 'vue';
import { copy } from 'v-copy';
import SCard from '@stratumfive/ui-baseplate/src/components/SCard/SCard.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';

export default Vue.extend({
  name: 'SystemCompatibilityQuickActions',
  directives: { copy },
  components: {
    SCard,
    SButton,
  },
  data(): any {
    return { copyOverlayActive: false };
  },
  computed: {
    systemOutput(): string {
      return this.$store.getters['systemCompatibility/getSystemOutputAsString'];
    },
    dataReady(): boolean {
      return this.$store.state.systemCompatibility.dataReady;
    },
  },
  methods: {
    /**
     * Show a success message after copying the system output to the user's clipboard for a short
     * amount of time.
     */
    afterCopySystemOutput(): void {
      this.copyOverlayActive = true;

      setTimeout(() => {
        this.copyOverlayActive = false;
      }, 1800);
    },
  },
});
