




































import Vue from 'vue';

export default Vue.extend({
  name: 'SystemResultIcon',
  props: {
    result: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
