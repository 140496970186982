












































import Vue from 'vue';
import { detect as DetectBrowser } from 'detect-browser';
import { copy } from 'v-copy';
import SCard from '@stratumfive/ui-baseplate/src/components/SCard/SCard.vue';
import SNameValue from '@stratumfive/ui-baseplate/src/components/SNameValue/SNameValue.vue';
import STooltip from '@stratumfive/ui-baseplate/src/components/STooltip/STooltip.vue';

interface NameValue {
  name: string;
  value: string;
}

export default Vue.extend({
  name: 'SystemStats',
  components: {
    SCard,
    SNameValue,
    STooltip,
  },
  directives: { copy },
  data(): any {
    return {
      clipboardCopy: 'Click to copy to clipboard',
      clipboardText: '',
    };
  },
  computed: {
    snapshotVersion(): string | undefined {
      return this.$store.state.systemCompatibility.version;
    },
    browserData(): any {
      return DetectBrowser();
    },
    formattedBrowserData(): NameValue[] {
      const { browserData } = this;
      return [
        { name: 'Browser', value: browserData.name || '-' },
        { name: 'Browser Version', value: browserData.version || '-' },
        { name: 'Operating System', value: browserData.os || '-' },
      ];
    },
  },
  created() {
    // Set version in the store as other components will need to display this.
    this.$store.commit('systemCompatibility/setVersion', process.env.VUE_APP_VERSION);
    this.clipboardText = this.clipboardCopy;
  },
  methods: {
    /**
     * Runs after each time the copy to clipboard functionality is used on the Snapshot version number.
     */
    afterCopyVersion(): void {
      this.clipboardText = 'Copied!';
      setTimeout(() => { this.clipboardText = this.clipboardCopy; }, 1000);
    },
  },
});
