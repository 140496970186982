


















































































import Vue from 'vue';
import dayjs from 'dayjs';
import STable from '@stratumfive/ui-baseplate/src/components/STable/STable.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';
import STooltip from '@stratumfive/ui-baseplate/src/components/STooltip/STooltip.vue';
import SPopup from '@stratumfive/ui-baseplate/src/components/SPopup/SPopup.vue';
import { API } from '@/API';
import SystemResultIcon from '@/components/compatibility/SystemResultIcon.vue';
import {
  CheckAPICommunication,
  CheckBrowserCompatibility,
  CheckLocalStorage,
  CheckGoogleAnalytics,
  CheckIndexedDB,
  CheckSentry,
  CheckServiceWorker,
  CheckStorageQuota,
} from '@/components/compatibility/SystemCompatibilityChecks';

export default Vue.extend({
  name: 'SystemCompatibilityTable',
  components: {
    STable,
    SButton,
    SystemResultIcon,
    SPopup,
  },
  props: {
    /**
     * Delay before running and re-running checks in ms.
     * This is used to represent that the checks are being re-run visually.
     */
    checkDelay: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data(): any {
    return {
      learnMoreTitle: '',
      learnMoreCopy: '',
      learnMoreModalActive: false,
      headers: [
        { text: 'System Check', sortable: false },
        { text: 'Status', sortable: false, classNames: ['text-center'] },
        { text: 'Further Information', sortable: false },
      ],
      tableData: [
        {
          parameter: 'API Communication',
          key: 'apiCommunication',
          checkFunction: CheckAPICommunication,
          loading: true,
          tooltip: `This check attempts to connect to the Snapshot API. This is important for
            saving, and loading your data.`,
        },
        {
          parameter: 'Browser Compatibility',
          key: 'browserCompatibility',
          checkFunction: CheckBrowserCompatibility,
          loading: true,
          tooltip: 'Browsers may behave differently when using Snapshot. We recommend using Google Chrome.',
        },
        {
          parameter: 'Local Storage',
          key: 'localStorageEnabled',
          checkFunction: CheckLocalStorage,
          loading: true,
          tooltip: `Snapshot uses local storage to load data quicker,
            and to save data should you lose your internet connection.`,
        },
        {
          parameter: 'Google Analytics',
          key: 'googleAnalytics',
          checkFunction: CheckGoogleAnalytics,
          loading: true,
        },
        {
          parameter: 'IndexedDB',
          key: 'indexedDBAccess',
          checkFunction: CheckIndexedDB,
          loading: true,
          tooltip: `Snapshot uses IndexedDB to save larger amounts of data on your system. For example,
            if you submit a form but have no internet connection, Snapshot will save this locally until you're
            back online.`,
        },
        {
          parameter: 'Sentry',
          key: 'sentry',
          checkFunction: CheckSentry,
          loading: true,
        },
        {
          parameter: 'Service Worker',
          key: 'serviceWorker',
          checkFunction: CheckServiceWorker,
          loading: true,
        },
        {
          parameter: 'Storage Quota (estimated)',
          key: 'storageQuota',
          checkFunction: CheckStorageQuota,
          loading: true,
          tooltip: 'How much space Snapshot is using on your system.',
        },
      ],
    };
  },
  computed: {
    /**
     * Returns true after all checks have been reset to NOT loading.
     */
    allDataReady(): boolean {
      return this.tableData.every((row) => row.loading === false);
    },
  },
  watch: {
    /**
     * If @allDataReady is set to true, this means a system check has just finished running,
     * as all check items begin with a @loading value of true.
     *
     * Also, we're setting this, along with the output time, in the store as for other components.
     */
    allDataReady() {
      this.$store.commit('systemCompatibility/setDataReady', this.allDataReady);

      if (this.allDataReady) {
        const outputTime = dayjs().utc().format('DD-MM-YYYY [at] HH:mm:ss [UTC]');
        this.$store.commit('systemCompatibility/setLastCheckTime', { outputTime });
        this.$store.commit('systemCompatibility/setSystemCheckOutput', { systemCheckOutput: this.tableData });
      }
    },
  },
  mounted() {
    this.runSystemChecks();
  },
  methods: {
    /**
     * Loop through each table data item and run its associated check. In turn, this will
     * update all the values of the table.
     */
    runSystemChecks(): void {
      this.tableData.forEach((row) => {
        row.loading = true;

        // The manual delay prop, so that we can show the user that these checks are actually running.
        setTimeout(() => {
          this.runCheck(row);
        }, this.checkDelay);
      });
    },
    /**
     * By using Promise.resolve(), we can pass in both regular and Promise based functions.
     * This is called for the check function of every item.
     */
    runCheck(row: any): void {
      // Run our check function related to the row passed in.
      Promise.resolve(row.checkFunction()).then((result) => {
        const newItem = {
          result: result.value,
          loading: false,
          key: row.key,
          errorMessage: result.errorMessage || undefined,
          furtherInformation: result.furtherInformation || undefined,
        };

        // Set and display our new data.
        this.setDataItem(newItem);
      });
    },
    /**
     * Set an item in the tableData array to make sure that Vue knows to update.
     */
    setDataItem(item): void {
      const indexToUpdate = this.tableData.findIndex((row) => (row.key === item.key));
      Object.assign(this.tableData[indexToUpdate], item);
    },
    /**
     * Sets the modal to active, with the copy and title passed in that is linked to the 'learn more'
     * icons next to parameter names in the check table.
     */
    setLearnMoreModal(title, copy): void {
      this.learnMoreTitle = title;
      this.learnMoreCopy = copy;
      this.learnMoreModalActive = true;
    },
  },
});
